import React, { useState, useContext, useEffect } from "react";
import BASE_URLS from "../baseUrl";
import { useContact } from "./ContactContext";

const CurrContext = React.createContext();

// const BASE_CURR = {
//   main: "AED",
// };
// export default BASE_CURR;

//Hook method
export function useCurr() {
  return useContext(CurrContext);
}

export function CurrencyProvider(props) {
  const [currDrop, setcurrDrop] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("curr_selected") !== ""
      ? localStorage.getItem("curr_selected")
      : "AED"
  );
  const mainApiUrl = BASE_URLS.main;

  /*useEffect(() => {
    const getCurr = async () => {
      const res = await fetch(`${mainApiUrl}/get_curr.php`);
      const getdata = await res.json();

      setSelectedCurrency(getdata.country_curr);
      localStorage.setItem("curr_selected", getdata.country_curr);
      setcurrDrop(getdata.cont_arr);
      // console.log("selectedCurrency...", selectedCurrency);
      // console.log("currDrop...", currDrop);
      // console.log("getdata.cont_arr...", getdata.cont_arr);

    };
    getCurr();
  }, [mainApiUrl]);*/
  const { addr, loading } = useContact();

  useEffect(() => {
    if (addr) {
      setSelectedCurrency(addr.country_curr);
      localStorage.setItem("curr_selected", addr.country_curr);
      setcurrDrop(addr.cont_arr);
    }
  }, [addr, setSelectedCurrency]);


  if (loading) {
    return (
      <div id="preloader"></div>
    )
  }

  

  //   console.log("currDrop...", currDrop);
  const currConvert = (price) => {
    // console.log(parseInt(price));
    // console.log("getCurrRate...", getExchangeRateByCurrency(selectedCurrency));
    if (parseFloat(price) === 0) {
      return price;
    } else {
      return (
        parseFloat(price) * getExchangeRateByCurrency(selectedCurrency)
      ).toFixed(2);
    }
  };

  const currConvertOG = (price) => {
    // console.log(parseInt(price));
    // console.log("getCurrRate...", getExchangeRateByCurrency(selectedCurrency));
    // console.log("selectedCurrency...", selectedCurrency);
    if (parseFloat(price) === 0) {
      return price;
    } else {
      return (
        parseFloat(price) / getExchangeRateByCurrency(selectedCurrency)
      ).toFixed(2);
    }
  };

  const getExchangeRateByCurrency = (currency) => {
    const country = currDrop.find((item) => item.country_curr == currency);
    return country ? country.country_curr_ex_rate : 1;
  };

  const value = {
    currDrop,
    selectedCurrency,
    setSelectedCurrency,
    currConvert,
    currConvertOG,
  };

  return (
    <CurrContext.Provider value={value}>{props.children}</CurrContext.Provider>
  );
}
