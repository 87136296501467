import React, { useState, useContext } from "react";
import BASE_URLS from "../baseUrl";
import { toast } from "react-toastify";
import { useAuth } from "./AuthContext";

const WishlistContext = React.createContext();

// Hook method
export function useWishlist() {
  return useContext(WishlistContext);
}

export function WishlistProvider(props) {
  const mainApiUrl = BASE_URLS.main;
  const [wishlistItems, setWishlistItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [heartToggle, setHeartToggle] = useState(false);
  const [wishlistStatus, setWishlistStatus] = useState({});
  const [tourListWishlist, setTourListWishlist] = useState({});
  const [actSidebarWishlist, setActSidebatwishlist] = useState({});
  const { isLoggedIn, openLoginModal } = useAuth();
  const [wishlistRefreshTrigger, setWishlistRefreshTrigger] = useState(0);
  // useState(false);

  const getWishlistItems = async () => {
    // const payload = {
    //   user_id: localStorage.getItem("setAuthUser"),
    // };
    // try {
    //   const response = await fetch(`${mainApiUrl}/get_wishlist_items.php`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   });

    //   if (response.ok) {
    //     const getdata = await response.json();
    //     setWishlistItems(getdata.wishlist_list);
    //   }
    // } catch (error) {
    //   // console.error("Error:", error);
    // }
  };

  // const getProductsData = async (id) => {
  //   if (id != '' && id != undefined) {
  //     const res = await fetch(
  //       `${mainApiUrl}/get_products_data.php?product_slug=` + id
  //     );
  //     const getdata = await res.json();
  //     setSelectedProduct(getdata);
  //   }
  // };
  const setAuthUser = localStorage.getItem("setAuthUser");

  const getProductsData = async (id) => {
    if (id != '' && id != undefined) {
      const dataToSend = {
        setAuthUser: setAuthUser,
      };
      try {
        const response = await fetch(
          `${mainApiUrl}/get_products_data.php?product_slug=` + id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );
        const getdata = await response.json();
        setSelectedProduct(getdata);
      } catch (error) {
        console.error(error);
      } finally {
      }
    }
  };

  const handleRemoveItem = async (product) => {
    //   // e.preventDefault();

    //   const payload = {
    //     product_id: product,
    //     table_name: "tbl_product_wishlist",
    //     where_field: "product_id",
    //   };
    //   try {
    //     const response = await fetch(`${mainApiUrl}/remove_wishlist.php`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify(payload),
    //     });
    //     //console.log("Response:", response); // Log the entire response
    //     if (response.ok) {
    //       toast.success("Wishlist item Removed!", {
    //         position: toast.POSITION.TOP_CENTER,
    //         autoClose: 1000,
    //       });
    //     } else {
    //       toast.success("An error occured while removing wishlist", {
    //         position: toast.POSITION.TOP_CENTER,
    //         autoClose: 1000,
    //       });
    //     }
    //   } catch (error) {
    //     //console.error("Error:", error);
    //     toast.success("An error occured while removing wishlist", {
    //       position: toast.POSITION.TOP_CENTER,
    //       autoClose: 1000,
    //     });
    //   }
  };

  const insertWishlist = async (wishlist_payload) => {
    // console.log(wishlist_payload);
    try {
      // Send a POST request to your API to add the product to the wishlist
      const response = await fetch(`${mainApiUrl}/insert_wishlist.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(wishlist_payload),
      });
      const getdata = await response.json();
      if (response.ok && getdata.type == 'insert') {
        setWishlistRefreshTrigger(prev => prev + 1);
        // // Handle success, e.g., show a success message or update UI
        toast.success("Product added to wishlist", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        // getWishlistItems();
      } else if (response.ok && getdata.type == 'delete') {
        setWishlistRefreshTrigger(prev => prev + 1);
        // // Handle success, e.g., show a success message or update UI
        toast.success("Wishlist item Removed!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        // getWishlistItems();
      } else {
        // Handle error, e.g., show an error message
        toast.error("Failed to add/remove product to wishlist");
      }
    } catch (error) {
      // Handle network errors or other exceptions
      //console.error("Error:", error);
      toast.error("An error occurred while adding the product to wishlist");
    }
  };

  const handleWishlistClick = async (product) => {
    // Check if the user is logged in (you can use your isLoggedIn state)
    if (!isLoggedIn) {
      // Handle the case when the user is not logged in, e.g., show a login modal or redirect to the login page.
      // You can use state management (like Redux) or a global context to manage user authentication.
      openLoginModal();
      return;
    }

    // if (wishlistStatus[product] || tourListWishlist[product] || actSidebarWishlist[product]) {
    //   setWishlistItems((current) =>
    //     current.filter((wishlistItems) => wishlistItems.product_id !== product)
    //   );

    //   setWishlistStatus((prevStatus) => ({
    //     ...prevStatus,
    //     [product]: false,
    //   }));
    //   setTourListWishlist((prevStatus) => ({
    //     ...prevStatus,
    //     [product]: false,
    //   }));
    //   setActSidebatwishlist((prevStatus) => ({
    //     ...prevStatus,
    //     [product]: false,
    //   }));

    //   handleRemoveItem(product);
    // } else {
    const decrypt_user_id = localStorage.getItem("setAuthUser");
    //   // Create a data object to send to the API
    const wishlist_payload = {
      product_id: product,
      created_by: decrypt_user_id,
    };
    //   setWishlistStatus((prevStatus) => ({
    //     ...prevStatus,
    //     [product]: true,
    //   }));

    insertWishlist(wishlist_payload);
    // }
  };

  const value = {
    wishlistItems,
    setWishlistItems,
    getWishlistItems,
    selectedProduct,
    setSelectedProduct,
    getProductsData,
    heartToggle,
    setHeartToggle,
    handleRemoveItem,
    wishlistStatus,
    insertWishlist,
    setWishlistStatus,
    handleWishlistClick,
    tourListWishlist,
    setTourListWishlist,
    actSidebarWishlist, setActSidebatwishlist, wishlistRefreshTrigger
  };

  return (
    <WishlistContext.Provider value={value}>
      {props.children}
    </WishlistContext.Provider>
  );
}
