import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useNavigate } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import { useCurr } from "../../Contexts/currContext";

function formatDate(date) {
  // Try to parse the date
  let parsedDate = new Date(date);

  // Check if the date is valid
  if (isNaN(parsedDate)) {
    // If the date is invalid, use the current date
    parsedDate = new Date();
  }

  // Define the formatting options
  const options = { weekday: "short", day: "2-digit", month: "short", year: "numeric" };

  // Return the formatted date string
  return parsedDate.toLocaleDateString("en-US", options);
}

// function dateFormat(selectedDate) {
//   const currentYear = new Date().getFullYear();
//   const fullDateString = selectedDate.toString() + " " + currentYear;

//   const convertedDate = new Date(fullDateString);
//   const formattedDate =
//     convertedDate.getFullYear() +
//     "-" +
//     (convertedDate.getMonth() + 1) +
//     "-" +
//     convertedDate.getDate();
//   return formattedDate;
// }

function dateFormat(selectedDate) {
  try {
    // Try to parse the date
    let convertedDate = new Date(selectedDate);

    // If the date is invalid, set it to the current date
    if (isNaN(convertedDate)) {
      convertedDate = new Date();
    }

    // Format the date into YYYY-MM-DD
    const formattedDate =
      convertedDate.getFullYear() +
      "-" +
      String(convertedDate.getMonth() + 1).padStart(2, '0') +
      "-" +
      String(convertedDate.getDate()).padStart(2, '0');

    return formattedDate;
  } catch (error) {
    // In case of any error, return the current date
    const currentDate = new Date();
    return (
      currentDate.getFullYear() +
      "-" +
      String(currentDate.getMonth() + 1).padStart(2, '0') +
      "-" +
      String(currentDate.getDate()).padStart(2, '0')
    );
  }
}

function getCurrentTime() {
  const currentTime = new Date();
  // Extract hours, minutes, and seconds from the current time
  const hours = currentTime.getHours().toString().padStart(2, "0");
  const minutes = currentTime.getMinutes().toString().padStart(2, "0");
  const seconds = currentTime.getSeconds().toString().padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}

const TimeConverter = (inputTime) => {
  if (inputTime == undefined) {
    return "";
  }
  const date = new Date(`2000-01-01T${inputTime}`);
  const formattedTime = date.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return formattedTime;
};

export const SelectPreference = ({
  item,
  proData,
  selectedDate,
  showPreference,
  openCalendarModal,
  hidePreferenceModal,
  handleEditDate,
  newJoinedValue,
  selectedTimeSlot,
  setShowPreferenceModal,
  onHide,
}) => {
  const webUrl = BASE_URLS.webUrl;
  const navigate = useNavigate();
  const { selectedCurrency, currConvert } = useCurr();

  const handleIncrease = (itemId) => {
    // Find the item by itemId and update its quantity
    const updatedDisplayTickets = displayTickets.map((item) => {
      if (item.id === itemId) {
        var addQty = 1;

        if (item.tick_min_buy > item.quantity) { var addQty = item.tick_min_buy; }
        return {
          ...item,
          quantity: Math.min(item.quantity + addQty, item.tick_max_buy),
        };
      }
      return item;
    });
    setdisplayTickets(updatedDisplayTickets);
  };

  const handleDecrease = (itemId) => {
    // Find the item by itemId and update its quantity
    const updatedDisplayTickets = displayTickets.map((item) => {
      if (item.id === itemId) {
        var minusQty = 1;

        if (item.tick_min_buy == item.quantity) { var minusQty = item.quantity; }
        return {
          ...item,
          quantity: Math.max(item.quantity - minusQty, 0),
        };
      }
      return item;
    });
    setdisplayTickets(updatedDisplayTickets);
  };

  const [prodTags, setProdTags] = useState([]);
  useEffect(() => {
    if (proData) {
      setProdTags(proData.pro_arr);
    }
  }, [proData]);

  const [displayTickets, setdisplayTickets] = useState([]);
  const [allTicketData, setAllTicketData] = useState([]);
  const [displayTicketsResult, setdisplayTicketsResult] = useState([]);

  const mainApiUrl = BASE_URLS.main;

  // const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(true);

  // useEffect(() => {
  //   const checkoutDisabled = displayTickets.some((item) => ticket.quantity < ticket.tick_min_buy);
  //   setIsCheckoutDisabled(checkoutDisabled);
  // }, [displayTickets]);

  useEffect(() => {
    if (item) {
      const getTicketDets = async () => {
        // e.preventDefault();
        const payload = {
          item: item,
          selectedDate: formatDate(selectedDate),
          newJoinedValue: newJoinedValue,
          selectedTimeSlot: selectedTimeSlot,
        };
        setAllTicketData([]);
        setdisplayTicketsResult([]);
        try {
          const response = await fetch(
            `${mainApiUrl}/get_products_tickets_data.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );

          if (response.ok) {
            const data = await response.json();
            if (data.display_tickets && data.display_tickets.default_tickets) {
              setAllTicketData(data.display_tickets.ticketString);
              setdisplayTicketsResult(data.display_tickets.default_tickets);
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
      getTicketDets();
    }
  }, [item, selectedDate, newJoinedValue, selectedTimeSlot]);

  useEffect(() => {
    if (displayTicketsResult) {
      // Initialize the quantity for each item in displayTickets with a unique ID
      const initialDisplayTickets = displayTicketsResult.map(
        (ticket, index) => ({
          ...ticket,
          quantity: 0,
          id: index, // Assign a unique ID to each item
        })
      );
      setdisplayTickets(initialDisplayTickets);
    }
  }, [displayTicketsResult, selectedDate]);

  // Calculate the total amount based on the quantities and prices of individual items

  const totalAmount = displayTickets.reduce((total, item) => {
    // Check if item.inv_dets[0] is not null and has a property sell_price
    const sellPrice =
      item &&
        item.inv_dets &&
        item.inv_dets[0] &&
        item.inv_dets[0].sell_price !== null
        ? item.inv_dets[0].sell_price
        : 1;
    return total + item.quantity * currConvert(sellPrice);
  }, 0);
  const totalAmountDisp = displayTickets.reduce((total, item) => {
    // Check if item.inv_dets[0] is not null and has a property sell_price
    const sellPrice =
      item &&
        item.inv_dets &&
        item.inv_dets[0] &&
        item.inv_dets[0].sell_price !== null
        ? item.inv_dets[0].sell_price
        : 1;
    return total + item.quantity * sellPrice;
  }, 0);

  const lastAccessTime = localStorage.getItem("lastAccessTime");
  const setAuthUser = localStorage.getItem("setAuthUser");

  const handleCheckout = () => {
    // Prepare the data to send to the PHP API
    const bookingDetails = {
      selectedDate: formatDate(selectedDate ? selectedDate.toString() : new Date().toString()),
      selectedTickets: displayTickets,
      user_id: setAuthUser,
      lastAccessTime: lastAccessTime,
      product_id: proData.product_name,
      totalAmount,
      package_name: item.actTitle,
      allTicketData: allTicketData,
      product_id_num: proData.products_id,
      actual_amount: totalAmount,
      booking_date: dateFormat(selectedDate),
      booking_time: selectedTimeSlot,
      totalAmountDisp: totalAmountDisp,
      pro_arr: proData.pro_arr,
      addon_prod_slug: proData.addon_prod_slug,
      // package_name:
      // Add any other relevant data here
    };
    navigate(`${webUrl}checkout`, { state: { bookingDetails } });
  };

  const isButtonEnabled = () => {
    var totQty = 0;
    for (const ticket of displayTickets) {
      if ((ticket.quantity > 0 && ticket.quantity >= parseInt(ticket.tick_min_buy)) || ticket.quantity == 0) {
        var totQty = totQty + ticket.quantity;
        continue; // If condition met, continue to next ticket
      } else {
        return false; // If condition not met for any ticket, return false
      }
    }
    if (totQty == 0) {
      return false; // If condition not met for any ticket, return false
    } else {
      return true; // If condition met for all tickets, return true
    }
  };

  // const isCheckoutDisabled = displayTickets.some((ticket) => ticket.quantity < ticket.tick_min_buy);
  console.log( displayTickets);

  return (
    <>
      <Modal
        show={showPreference}
        onHide={hidePreferenceModal}
        size="lg"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Select your preferences</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {proData && item ? (
            <div className="alert alert-info" role="alert">
              <h5>{proData.product_name}</h5>
              <p>{allTicketData}</p>
              {proData.tick_type !== 3 ? (
                <div className="d-flex justify-content-between align-items-center pt-2">
                  {selectedDate && (
                    <p className="date font-weight-bold">
                      {formatDate(selectedDate ? selectedDate.toString() : new Date().toString())}{" "}
                      {TimeConverter(selectedTimeSlot)}
                    </p>
                  )}
                  <p
                    className="text-blue font-weight-bold"
                    onClick={() => {
                      onHide();
                      handleEditDate();
                      openCalendarModal(item);
                      hidePreferenceModal();
                    }}
                  >
                    <i className="bi bi-pencil"></i> Edit date
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <p>Loading...</p>
          )}
          {prodTags &&
            Array.isArray(prodTags) &&
            prodTags.map((tags, index) => (
              <span className="badge bg-secondary pt-2 pb-2" key={index}>
                {tags.pro_tag_name}
              </span>
            ))}
          <div className="qty-body mt-3">
            <div className="quantity-details">
              <h5 className="d-grid gap-1">Quantity</h5>
            </div>

            <div className="quantity-calc">
              {displayTickets && Array.isArray(displayTickets)
                ? displayTickets.map((item, index) => (
                  <div
                    className="quantity-calc-list d-flex justify-content-between"
                    key={index}
                  >
                    <div className="quantity">
                      <h6 className="mb-1">{item.tick_ids_name} </h6>
                      <p className="small text-muted">
                        {item.tick_op_notes}{" "}
                      </p>
                      {item && item.inv_dets && item.inv_dets[0] && item.inv_dets[0].sell_price && parseFloat(item.inv_dets[0].sell_price) < parseFloat(item.inv_dets[0].original_price) ? (
                        <div className="containers pb-2">
                          <s>
                            <span className="currency">
                              {selectedCurrency}
                            </span>{" "}
                            {currConvert(item.inv_dets[0].original_price)}
                          </s>
                        </div>
                      ) : null}

                      <h6 className="mb-0 mr-4 fw-bold fs-6">
                        {selectedCurrency}{" "}
                        {item && item.inv_dets && item.inv_dets[0] && item.inv_dets[0].sell_price && item.inv_dets[0].sell_price !== null
                          ? currConvert(item.inv_dets[0].sell_price)
                          : 0}
                        {item && item.inv_dets && item.inv_dets[0] && item.inv_dets[0].sell_price && item.inv_dets[0].sell_price !== null && parseFloat(item.inv_dets[0].sell_price) < parseFloat(item.inv_dets[0].original_price) ? (
                          <span className="hot_badge">
                            {item.inv_dets[0].tick_discount}{" "}
                          </span>
                        ) : null}
                      </h6>
                    </div>
                    <div className="text-center width_120">
                      <div className="quantity1">
                        <div className="numberstyle-qty">
                          <div
                            className="qty-btn qty-rem"
                            onClick={() => handleDecrease(item.id)}
                          >
                            {" "}
                            -{" "}
                          </div>
                          <input
                            className="numberstyle"
                            type="number"
                            min={item.tick_min_buy}
                            max={item.tick_max_buy}
                            step="1"
                            value={item.quantity || 0}
                            readOnly
                          />
                          <div
                            className="qty-btn qty-add"
                            onClick={() => handleIncrease(item.id)}
                          >
                            {" "}
                            +{" "}
                          </div>
                        </div>
                      </div>
                      <p className="pt-3 small text-muted">
                        Min : {item.tick_min_buy} | Max : {item.tick_max_buy}
                      </p>
                    </div>
                  </div>
                ))
                : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="d-grid">
            <div>
              <h5>Total</h5>
            </div>
            <div className="d-grid">
              <h5 className="mb-0">
                {selectedCurrency} {totalAmount.toFixed(2)}
              </h5>
            </div>
          </div>
          {/* {displayTickets.every((item) => item.quantity === 0) && isButtonEnabled() ? ( */}
          {(displayTickets && displayTickets.length === 0) ? (
            <p className="text-danger">Not Available</p>
          ) : (
            isButtonEnabled() ? (
              <Button variant="primary" onClick={handleCheckout}>
                Checkout
              </Button>
            ) : (
              <Button variant="primary" disabled>
                Checkout
              </Button>
            )
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SelectPreference;
