import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Activities from "../ThingsToDo/Activities";
import Highlights from "../Home/Highlights";
import { Col, Container, Row } from "react-bootstrap";
import ActivitySlider from "./ActivitySlider";
import { ActivityDetails } from "./ActivityDetails";
import { ActivitySidebar } from "./ActivitySidebar";
import { ActivityName } from "./ActivityName";
import ActivityTours from "./ActivityTours";
import { MobileBottom } from "./MobileBottom";
import BASE_URLS from "../../baseUrl";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import { NotFound } from "../NotFound";
import { useWishlist } from "../../Contexts/WishlistContext";

import { useParams, useNavigate } from "react-router-dom";

export const Activity = ({ openCalendarModal, showPreferenceModal }) => {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const { id } = useParams();
  const [proData, setProd] = useState([]);
  const [proDataArr, setproDataArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { wishlistRefreshTrigger } = useWishlist();
  // useEffect(() => {
  //   const getProduct = async () => {
  //     if (id != '' && id != undefined) {
  //       console.log(id);
  //       const res = await fetch(
  //         `${mainApiUrl}/get_products_data.php?product_slug=` + id
  //       );
  //       const getdata = await res.json();
  //       if (getdata) {
  //       setproDataArr(getdata);
  //         setProd(getdata);
  //       }
  //     }
  //   };

  //   getProduct();
  // }, [mainApiUrl, id]);


  // useEffect(() => {
  //   const getProduct = async () => {
  //     try {
  //       if (id !== '' && id !== undefined) {
  //         const res = await fetch(`${mainApiUrl}/get_products_data.php?product_slug=${id}`);
  //         const getdata = await res.json();
  //         if (getdata.status == 1) {
  //           setproDataArr(getdata);
  //           setProd(getdata);
  //           const existingDataA = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
  //           const newDataA = { productId: getdata.products_id };
  //           const updatedDataA = [...existingDataA, newDataA];
  //           localStorage.setItem('recentlyViewed', JSON.stringify(updatedDataA));
  //         } else {
  //           navigate(`${webUrl}` + "NotFound");

  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       navigate(`${webUrl}` + "NotFound");
  //     }
  //     finally {
  //       setIsLoading(false);
  //     }

  //   };

  //   getProduct();
  // }, [mainApiUrl, id]);
  const setAuthUser = localStorage.getItem("setAuthUser");

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        setAuthUser: setAuthUser,
      };
      try {
        const response = await fetch(
          `${mainApiUrl}/get_products_data.php?product_slug=` + id,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );
        const getdata = await response.json();
        setproDataArr(getdata);
        setProd(getdata);
        const existingDataA = JSON.parse(localStorage.getItem('recentlyViewed')) || [];
        const newDataA = { productId: getdata.products_id };
        const updatedDataA = [...existingDataA, newDataA];
        localStorage.setItem('recentlyViewed', JSON.stringify(updatedDataA));

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [mainApiUrl, id, wishlistRefreshTrigger]);

  const [actList, setActList] = useState([]);

  useEffect(() => {
    if (proData.display_tickets_new) {
      setActList(proData.display_tickets_new.specification_value_names);
    }
  }, [proData.display_tickets_new]);

  return (
    <div className="body-content">
      <Helmet>
        <title>{proData.product_name}</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          {isLoading ?
            <Skeleton animation="wave" variant="text" width="30%" height={20} />
            :
            <div className="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <Link to={webUrl}>Home</Link>
                </li>
                <li>{proData.city_name}</li>
                <li>{proData.product_name}</li>
              </ol>
            </div>
          }
        </Container>
      </div>
      <section className="blog-details pb-2">
        <Container>
          <Row>
            <ActivityName proData={proDataArr} />
          </Row>
        </Container>
      </section>
      <Container fluid>
        <ActivitySlider proData={proDataArr} />
      </Container>
      <section className="blog-details pt-3 overflow-visible">
        <Container>
          <Row>
            <Col lg={9} md={9}>
              <article className="article">
                <ActivityDetails
                  proData={proDataArr}
                  actList={actList}
                  openCalendarModal={openCalendarModal}
                />
              </article>
            </Col>
            <Col lg={3} md={3}>
              <ActivitySidebar
                proData={proDataArr}
                showPreference={showPreferenceModal}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Highlights />
      <ActivityTours proData={proDataArr} id={id} />
      <Activities />
      <MobileBottom proData={proDataArr} />
    </div>
  );
};
