import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import BannerHighlights from './BannerHighlights';
import SearchResult from './searchResult';
import BASE_URLS from '../../baseUrl';
import { useContact } from '../../Contexts/ContactContext';

function HomeBanner() {
  const mainApiUrl = BASE_URLS.main;
  const [banners, setBanner] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentBanner, setCurrentBanner] = useState(0);
  const [prevBanner, setPrevBanner] = useState(banners.length - 1);

  const formRef = useRef(null);

/*  useEffect(() => {

    const getCurrBanner = async () => {
      const resBanner = await fetch(`${mainApiUrl}/get_banner_image.php`);
      const getdataBanner = await resBanner.json();
      setBanner(getdataBanner.main_banner);
    }
    getCurrBanner();
  }, [mainApiUrl]);*/

  const { addr, loading } = useContact();

  useEffect(() => {
    if (addr) {
      setBanner(addr.main_banner);
    }
  }, [addr]);


 

  useEffect(() => {
    const interval = setInterval(() => {
      setPrevBanner(currentBanner);
      setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [banners.length, currentBanner]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowSearchResults(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowSearchResults(true);
  };

  if (loading) {
    return (
      <div id="preloader"></div>
    )
  }
  return (
    <section className="hero">
      {banners.map((banner, index) => (
        <img
          fetchpriority="high" 
          decoding="async"
          rel="preload" 
          as="image"
          key={index}
          src={banner.mbanner_image}
          alt={banner.mbanner_alt}
          className={`banner-image transition-1 ${index === currentBanner ? 'active' : 'inactive'} ${index === currentBanner || index === prevBanner ? 'opacity-100' : 'opacity-0'}`}
          width="100%" 
          height="auto"
      />
      ))}
      <Container>
        <Row>
          <Col lg={10}>
            <h2 className="text-white">Travel that shapes you</h2>
            <p>Book activities, tours and experiences</p>
          </Col>
          <Col lg={5}>
            <form
              ref={formRef}
              action="#"
              className="position-relative"
              onSubmit={handleFormSubmit}
            >
              <div className="sign-up-form d-flex">
                <input
                  name='seach_destination'
                  type="search"
                  className="form-control location-search"
                  placeholder="Search for destinations or activities..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setShowSearchResults(true)}
                />
                <button type="submit" aria-label="Search Banner" className="btn btn-primary">
                  <i className="bi bi-search"></i>
                </button>
              </div>
              {showSearchResults && <SearchResult searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>}
            </form>
          </Col>
        </Row>
        <BannerHighlights />
      </Container>
    </section>
  );
}

export default HomeBanner;
