import React, { createContext, useState, useContext, useEffect } from "react";
import BASE_URLS from "../baseUrl";

// const ContactContext = createContext();
const ContactContext = React.createContext();

export const ContactProvider = ({ children }) => {
    const [addr, setAddr] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cityDatas, setDestData] = useState([]);

    useEffect(() => {
        fetchAddress();
        getCities();
    }, []);

    const fetchAddress = async () => {
        try {
            const res = await fetch(`${BASE_URLS.main}/get_logos.php`);
            const data = await res.json();
            setAddr(data);
        } catch (error) {
            console.error("Error fetching contact details:", error);
        } finally {
            setLoading(false);
        }
    };

    const getCities = async () => {
        try {
            const res = await fetch(`${BASE_URLS.main}/get_cities.php`);
            if (res.ok) {
                const getdata = await res.json();
                if (getdata) {
                    setDestData(getdata);
                }
            } else {
                console.error("Failed to fetch data from the API.");
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };

    return (
        <ContactContext.Provider value={{ addr, loading, cityDatas }}>
            {children}
        </ContactContext.Provider>
    );
};

export const useContact = () => useContext(ContactContext);
