import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from "../../baseUrl";
import { useFilter } from "../../Contexts/filterContext";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import { useContact } from "../../Contexts/ContactContext";

function Destinations() {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const [destData, setDestData] = useState([]);
  const { handleFilters } = useFilter();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  /*useEffect(() => {
    const getCities = async () => {
      try {
        const res = await fetch(`${mainApiUrl}/get_cities.php`);
        if (res.ok) {
          const getdata = await res.json();
          if (getdata.city_list && Array.isArray(getdata.city_list)) {
            setDestData(getdata.city_list);
          } else {
            console.error(
              "API response is missing or not in the expected format."
            );
          }
        } else {
          console.error("Failed to fetch data from the API.");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getCities();
  }, [mainApiUrl]);*/

  const { loading, cityDatas } = useContact();

  useEffect(() => {
      if (cityDatas) {
        setDestData(cityDatas.city_list_all.slice(0, 6));
        setIsLoading(false);
      }
  }, [cityDatas]);

  if (loading || !destData) {
      return (
          <div id="preloader"></div>
      )
  }


  const handleButtonClick = (item) => {
    handleFilters("", "", "", "", "", "", null, item.destTitle, null);
    navigate(`${webUrl}things-to-do/${item.destSlug}`, {
      state: { location: item.destTitle },
    });
  };

  //  // Hide the entire section if prodData length is zero
  //  if (destData.length === 0 && !isLoading) {
  //   return null;
  // }

  return (
    <section className="section-bg our-destination">
      <Container>
        <div className="section-header">
          <p>
            Explore <span>Our Destinations</span>
          </p>
        </div>
        <Row className="gy-3 justify-content-center">
            {isLoading
              ? Array.from({ length: 6 }).map((_, index) => (
                  <Col lg={2} md={4} sm={6} xs={6} key={index}>
                    <Skeleton animation="wave" variant="rounded" width="100%" height={298}/>
                  </Col>
                ))
              : destData.map((item, index) => (
            <Col lg={2} md={4} sm={6} xs={6} key={index}>
              <div className="destination">
                <button name={item.destAlt} onClick={() => handleButtonClick(item)}>
                  <div className="tour-img">
                    <img
                      loading="lazy"
                      src={item.destImg}
                      className="img-fluid"
                      alt={item.destAlt}
                      width="100%"
                      height="250px"
                    />
                  </div>
                  <div className="tour-info">
                    {item.destTitle && <h4>{item.destTitle}</h4>}
                  </div>
                </button>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-center">
            <Link to="destinations">
              {" "}
              <Button variant="primary">See All Destinations</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Destinations;
