import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from '../../baseUrl';
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import { useContact } from '../../Contexts/ContactContext';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function BookNow() {
    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;
    const [feaDataArr, setFeaDataArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    /*useEffect(() => {
        const getProduct = async () => {
            try {
                const res = await fetch(`${mainApiUrl}/get_subbanner_dets.php`);
                if (!res.ok) {
                    throw new Error('Failed to fetch data');
                }
                const getdata = await res.json();
                setFeaDataArr(getdata.feature_list);
            } catch (error) {
                console.error('Error fetching data:', error.message);
                // You can handle errors here, e.g., show an error message or set state to indicate an error
            } finally {
                setIsLoading(false);
              }
        };
    
        getProduct();
    }, [mainApiUrl]);*/

    const { addr, loading } = useContact();

    useEffect(() => {
        if (addr) {
            setFeaDataArr(addr.feature_list);
            setIsLoading(false);
        }
    }, [addr]);

    if (loading) {
        return (
            <div id="preloader"></div>
        )
    }


    const columnSize = feaDataArr.length === 1 ? 12 : 6;

    if (feaDataArr.length === 0 && !isLoading) {
        return null;
    }
    let slidesToShow = 3; // Default value for 2 or more items

    const settings = {
        dots: true,
        arrows: false,
        infinite: feaDataArr.length > 3, // Only enable infinite scroll if more than 3 items
        speed: 300,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: feaDataArr.length > 3, // Only autoplay if more than 3 items
        autoplaySpeed: 3000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: slidesToShow > 1 ? slidesToShow - 1 : 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <section className='booknow'>
            <Container>
                <div className="section-header">
                    <p>Offers for you</p>
                </div>
                <Row className="contact gy-4">
                <Slider {...settings}>
                        {isLoading
                            ? Array.from({ length: 2 }).map((_, index) => (
                                <Col lg={6} md={6} sm={12} key={index}>
                                    <Skeleton animation="wave" variant="rounded" width="100%" height={180} />
                                </Col>
                            ))
                            : feaDataArr.map((item, index) => (
                                <Col key={index}>
                                    <div className="image-banner rounded-3">
                                        <img src={item.img_file} alt={item.img_name} loading="lazy" width="100%" className="rounded-3"
                                            height="auto" />
                                        <div className="overlay rounded-3" ></div>
                                        <div className="button">
                                            <Link to={`${webUrl}activity/${item.product_slug}`}>
                                                Book Now
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                    </Slider>
                </Row>
            </Container>
        </section>
    );
}

export default BookNow;
