import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from "../baseUrl";
import empty from "../Images/empty.svg";
import { Button } from "react-bootstrap";
import { useFilter } from "../Contexts/filterContext";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
import { useContact } from "../Contexts/ContactContext";

function AllDestinations() {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const initialLoadLimit = 12;
  const loadMoreLimit = 8;
  const navigate = useNavigate();
  const { handleFilters } = useFilter();

  const [destData, setDestData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [visibleDestinations, setVisibleDestinations] =
    useState(initialLoadLimit);

  /*useEffect(() => {
    const getCities = async () => {
      try {
        const res = await fetch(`${mainApiUrl}/get_cities.php`);
        if (res.ok) {
          const getdata = await res.json();
          if (getdata.city_list_all) {
            setDestData(getdata.city_list_all);
            setLoading(false);
          }
        } else {
          console.error("Failed to fetch data from the API.");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };

    getCities();
  }, [mainApiUrl]);*/

  const { loading, cityDatas } = useContact();

  useEffect(() => {
      if (cityDatas) {
        setDestData(cityDatas.city_list_all);
        // setLoading(false);
      }
  }, [cityDatas]);

  if (loading) {
      return (
          <div id="preloader"></div>
      )
  }

  const loadMoreDestinations = () => {
    setVisibleDestinations((prevLimit) => prevLimit + loadMoreLimit);
  };

  const handleButtonClick = (destination) => {
    handleFilters("", "", "", "", "", "", null, destination.destTitle, null);
    navigate(`${webUrl}things-to-do/${destination.destSlug}`, {
      state: { location: destination.destTitle },
    });
  };

  return (
    <div className="body-content">
      <Helmet>
        <title>All Destination | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>All Destinations</li>
            </ol>
          </div>
        </Container>
      </div>
      <section>
        <Container>
          <div className="section-header">
            <p>
              Explore <span>Our Destinations</span>
            </p>
          </div>
          {loading ? (
            // Skeleton loading while data is being fetched
            <Row className="gy-4">
              {[...Array(initialLoadLimit)].map((_, index) => (
                <Col lg={2} md={4} xs={6} key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width="100%"
                    height={250}
                  />
                   <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={30}
                  />
                </Col>
              ))}
            </Row>
          ) : destData.length === 0 ? (
            // Empty state
            <div className="text-center">
              <img
                src={empty}
                width="500px"
                height="auto"
                className="img-fluid pt-3 pb-3"
                alt="Destination not available"
                loading="lazy"
              />
              <p className="pt-4 mb-1">
                Destinations are not available right now.
              </p>
              <p>Check back later for more exciting destinations.</p>
              <Link to={webUrl}>
                <Button variant="primary">Back to home</Button>
              </Link>
            </div>
          ) : (
            // Actual destination data
            <>
              <Row className="gy-4">
                {destData.slice(0, visibleDestinations).map((item, index) => (
                  <Col lg={2} md={4} xs={6} key={index}>
                    <div className="destination">
                      <button onClick={() => handleButtonClick(item)}>
                        <div className="tour-img">
                          <img
                            loading="lazy"
                            src={item.destImg}
                            className="img-fluid"
                            alt={item.destAlt}
                            width="100%"
                            height="auto"
                          />
                        </div>
                        <div className="tour-info">
                          <h4>{item.destTitle}</h4>
                        </div>
                      </button>
                    </div>
                  </Col>
                ))}
              </Row>
              {visibleDestinations < destData.length && (
                <div className="text-center mt-3">
                  <Button variant="primary" onClick={loadMoreDestinations}>
                    Load More
                  </Button>
                </div>
              )}
            </>
          )}
        </Container>
      </section>
    </div>
  );
}

export default AllDestinations;
