import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import TourList from "./TourList";
import { useNavigate } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import { useContact } from "../../Contexts/ContactContext";

function ToursTab() {
  const navigate = useNavigate();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const [activeTab, setActiveTab] = useState("");

  const handleTabSelect = (title) => {
    setActiveTab(title);
  };
  const setAuthUser = localStorage.getItem("setAuthUser");

  const [cityData, setCityData] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [cityProdData, setCityProdData] = useState([]);
  /* useEffect(() => {
     const getActivities = async () => {
       const res = await fetch(`${mainApiUrl}/get_cities.php`);
       const getdata = await res.json();
       setCityData(getdata.city_data_cont);
       setActiveTab(getdata.city_data_cont[0].destTitle);
     };
     getActivities();
   }, [mainApiUrl]);*/
  const { loading, cityDatas } = useContact();

  useEffect(() => {
    if (cityDatas && cityDatas.city_list_all.slice(0, 4)) {
      setCityData(cityDatas.city_list_all.slice(0, 4));
      setActiveTab(cityDatas.city_list_all[0].destTitle);
      setCityIds(cityDatas.cityIds);
    }
  }, [cityDatas]);

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        setAuthUser: setAuthUser,
      };
      try {
        const response = await fetch(
          `${mainApiUrl}/get_products.php?limit=4&city_id=${cityIds}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSend),
          }
        );
        const getdata = await response.json();
        setCityProdData(getdata.prod_list);
      } catch (error) {
        console.error(error);
      } finally {
      }
    };

    // const getActivities = async () => {
    //   const res = await fetch(`${mainApiUrl}/get_cities.php`);
    //   const getdata = await res.json();
    //   setCityData(getdata.city_data_cont);
    //   setActiveTab(getdata.city_data_cont[0].destTitle);
    // };
    if (cityIds && cityIds !='') {
      fetchData();
    }
  }, [mainApiUrl, cityIds]);

  if (loading && !cityProdData) {
    return (
      <div id="preloader"></div>
    )
  }

  const handleButtonClick = () => {
    navigate(`${webUrl}activity-list`, { state: { activeTab: activeTab } });
  };

  return (
    <section className="section-bg tour-tabs list-view">
      <Container>
        <div className="section-header">
          <p>
            Popular things to do in <span>{activeTab}</span>
          </p>
        </div>
        <Row className="tour-tabs">
          <Col>
            <Tabs
              id="tour-tabs"
              activeKey={activeTab}
              onSelect={(title) => handleTabSelect(title)}
            >
              {cityData.map((item, index) => (
                <Tab
                  key={index}
                  eventKey={item.destTitle}
                  title={
                    <span>
                      <i className="bi bi-geo-alt"></i> {item.destTitle}
                    </span>
                  }
                >
                  <Row className="gy-3 pt-5">
                    <TourList activeTab={activeTab} cityData={item} cityProdData={cityProdData} />
                  </Row>
                </Tab>
              ))}
            </Tabs>
            <Row className="tablist">
              <Col className="d-flex justify-content-center mt-5">
                <Button variant="primary" onClick={handleButtonClick}>
                  View All Activities
                </Button>
                { }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ToursTab;
