import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { SocialModal } from "./socialModal";
import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import BASE_URLS from "../../baseUrl";
import { useCurr } from "../../Contexts/currContext";
import { Skeleton } from "@mui/material";

export const ActivitySidebar = ({ proData }) => {
  const { selectedCurrency, currConvert } = useCurr();
  const [showSocialModal, setShowSocialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const openSocialModal = () => {
    setShowSocialModal(true);
  };
  const closeSocialModal = () => {
    setShowSocialModal(false);
  };

  const id = proData.products_id;
  const product_slug = proData.product_slug;
  const mainApiUrl = BASE_URLS.main;
  const {
    getProductsData,
    getWishlistItems,
    wishlistItems,
    actSidebarWishlist,
    setActSidebatwishlist,
  } = useWishlist();

  useEffect(() => {
    const fetchData = async () => {
      await getProductsData(product_slug);
      // await getWishlistItems();
    };
    setIsLoading(false);
    fetchData();
  }, [product_slug, mainApiUrl]);

  useEffect(() => {
    if (proData && wishlistItems) {
      const initialWishlist = {};
      initialWishlist[id] = wishlistItems.some(
        (item) => item.product_id === id
      );
      setActSidebatwishlist(initialWishlist);
    }
  }, [wishlistItems, proData]);

  return (
    <>
      <div className="sidebar mobile-none sticky-top">
        <div className="sidebar-item categories">
          {proData.min_original_price > 0 ? (
            <>
              <span>
                From{" "}
                {proData.original_price1 > proData.min_original_price ? (
                  <div className="containers pb-2 d-flex align-items-center justify-content-between">
                    <s>
                      <span className="currency">{selectedCurrency}</span>{" "}
                      {currConvert(proData.original_price1)}
                    </s>
                  </div>
                ) : null}
              </span>
              {isLoading ? ( // Render skeleton if loading
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="100%"
                  height={50}
                />
              ) : (
                <h3 className="sidebar-title fs-6 d-flex align-items-center justify-content-between">
                  {selectedCurrency} {currConvert(proData.min_original_price)} {proData.min_original_price < proData.original_price1 ? (<span className="hot_badge">{proData.discount}</span>) : null}
                </h3>
              )}
              <Button variant="primary" href="#next-dates-coupon">
                Book now
              </Button>
            </>
          ) :
            <>
              <span>
                From{" "}
              </span>
              {isLoading ? ( // Render skeleton if loading
                <Skeleton
                  animation="wave"
                  variant="text"
                  width="100%"
                  height={50}
                />
              ) : (
                <h3 className="sidebar-title fs-6 d-flex align-items-center justify-content-between">
                  {selectedCurrency} {currConvert(proData.product_price_starts_old)}
                </h3>
              )}
              <Button variant="primary" disabled>
                Book now
              </Button>
            </>
          }

          <div className="share-box">
            <div className="share-left">
              <p
                className="font-weight-bold view-modal"
                onClick={openSocialModal}
              >
                <i className="bi bi-share"></i> Share
              </p>
            </div>
            <div className="share-right">
              <p className="font-weight-bold">
                <WishlistButton
                  heartToggle={proData.wish_prod === 1}
                  product={proData.products_id}
                />{" "}
                Save
              </p>
            </div>
          </div>
        </div>
      </div>
      <SocialModal showSocial={showSocialModal} onHide={closeSocialModal} />
    </>
  );
};
